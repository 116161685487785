import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserModal from "./UserModal";
import Users from "./Users";
import TableToolbar from "../sub/bootstrap/TableToolbar";

class EstablishmentUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openModal() {
    this.setState({
      modal: (
        <UserModal
          establishment={this.props.establishment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    if (this.props.clients.length === 0) {
      return (
        <React.Fragment>
          <TableToolbar>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openModal()}
            >
              <FormattedMessage id="User.Add" />
            </button>
          </TableToolbar>
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Users" />
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.props.user.role !== 2 && (
          <TableToolbar>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openModal()}
            >
              <FormattedMessage id="User.Add" />
            </button>
          </TableToolbar>
        )}
        <Users clients={this.props.clients} />

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentUsers);
