import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EqAffectation from "../../enums/EqAffectation";
import EqOwner from "../../enums/EqOwner";
import EqState from "../../enums/EqState";
import EqMateriovigilance from "../../enums/EqMateriovigilance";
import EquipmentRow from "./EquipmentRow";
import EquipmentModal from "./EquipmentModal";
import RequestModal from "../requests/RequestModal";
import RequestTypes from "../../enums/RequestTypes";
import MaintenancesHistoryModal from "./maintenance/MaintenancesHistoryModal";
import Paginator from "../sub/Paginator";
import InterventionRequestsModal from "./InterventionRequestsModal";
import Util from "../../util/Util";
import APIUrl from "../../APIUrl";
import Icon from "../sub/Icon";
import MenuButton from "../sub/bootstrap/MenuButton";

class Equipments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      materialsTypesFilter: "",
      brandModelFilter: "",
      floorRoomFilter: "",
      affectationFilter: "",
      warrantyFilter: "",
      purchaseDateFilter: "",
      maintenanceDateFilter: "",
      stateFilter: "",
      ownerFilter: "",
      materiovigilanceFilter: "",
      orderedEquipments: this.props.equipments,
      orderBy: null,
      sortOrder: "ASC",

      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  editEquipment(equipment) {
    let materiovigilance = (
      <h6>
        <span className={"w-100 badge badge-light"}>-</span>
      </h6>
    );
    let materiovigilanceItem = this.props.materiovigilance.filter(
      (materiovigilance) => materiovigilance.model_id === equipment.model_id,
    )[0];
    // console.log("materiovigilanceItem", materiovigilanceItem);
    if (materiovigilanceItem !== undefined) {
      var mvClassName = "";
      if (parseInt(materiovigilanceItem.materiovigilance) === 1)
        mvClassName = "danger";
      else if (parseInt(materiovigilanceItem.materiovigilance) === 2)
        mvClassName = "info";
      else if (parseInt(materiovigilanceItem.materiovigilance) === 3)
        mvClassName = "warning";

      materiovigilance = (
        <div className={"form-control w-100 alert-" + mvClassName}>
          <h6 className="space-between">
            {this.props.intl.formatMessage({
              id: "EqMateriovigilance." + materiovigilanceItem.materiovigilance,
            })}
            {materiovigilanceItem.doc_url !== undefined &&
              materiovigilanceItem.doc_url !== null && (
                <a
                  role="button"
                  href={
                    APIUrl.getMateriovigilanceFile +
                    materiovigilanceItem._id +
                    "/" +
                    materiovigilanceItem.doc_url.split("/")[
                      materiovigilanceItem.doc_url.split("/").length - 1
                    ] +
                    "?token=" +
                    APIUrl.jwtToken
                  }
                  rel="noopener noreferrer"
                  download={
                    materiovigilanceItem +
                    "." +
                    materiovigilanceItem.doc_url.split("/")[
                      materiovigilanceItem.doc_url.split("/").length - 1
                    ]
                  }
                  target={"_blank"}
                >
                  <Icon
                    icon="download"
                    className={"icon-small alert-" + mvClassName}
                  />
                </a>
              )}
          </h6>
        </div>
      );
    } else {
      materiovigilance = (
        <div className={"form-control w-100 alert-success"}>
          <h6>
            {this.props.intl.formatMessage({ id: "EqMateriovigilance.0" })}
          </h6>
        </div>
      );
    }
    this.setState({
      modal: (
        <EquipmentModal
          close={() => this.closeModal()}
          establishmentId={this.props.establishmentId}
          equipment={equipment}
          required={true}
          materiovigilance={materiovigilance}
        />
      ),
    });
  }

  requestModal(equipment, RequestType) {
    if (RequestType === RequestTypes.INTERVENTION) {
      this.setState({
        modal: (
          <RequestModal
            isOpen={true}
            equipment={equipment}
            requestType={RequestTypes.INTERVENTION}
            required={true}
            close={() => this.closeModal()}
          />
        ),
      });
    } else {
      this.setState({
        modal: (
          <RequestModal
            isOpen={true}
            equipment={equipment}
            required={true}
            requestType={RequestTypes.RENEWAL}
            close={() => this.closeModal()}
          />
        ),
      });
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name;
    }
  }

  getModelName(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model.name;
    }
  }

  orderEquipmentsBy(sorting) {
    // console.log(sorting);
    // console.log(this.props.equipments);

    let sortParam; // will store an array of ordered values for param
    let equipmentParam; // The value to be tested
    let paramIds;

    this.setState({ orderBy: sorting });

    let direction;
    if (this.state.orderBy === sorting) {
      direction = this.state.sortOrder === "DESC" ? "ASC" : "DESC";
    } else {
      direction = "ASC";
    }

    this.setState({ sortOrder: direction });

    switch (sorting) {
      case "brand":
        equipmentParam = "brand_id";
        paramIds = this.props.brands;
        break;
      case "material_type":
        equipmentParam = "material_type_id";
        paramIds = this.props.materialsTypes;
        break;
      case "model_id":
        equipmentParam = "model_id";
        paramIds = this.props.models;
        break;
      default:
        break;
    }

    if (direction === "DESC") {
      sortParam = paramIds.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1,
      );
    } else {
      sortParam = paramIds.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
    }

    sortParam = sortParam.map(function (a) {
      return a._id;
    });

    var ordered = [];

    for (var value of sortParam) {
      for (var equipment of this.props.equipments) {
        if (value === equipment[equipmentParam]) ordered.push(equipment);
      }
    }

    this.setState({ orderedEquipments: ordered });
    // console.log(ordered);
    // return ordered;
  }

  openInterventionModal(equipment, establishmentSettings) {
    this.setState({
      modal: (
        <InterventionRequestsModal
          equipment={equipment}
          establishmentSettings={establishmentSettings}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openMaintenancesHistoryModal(equipment, maintenances, establishmentSettings) {
    this.setState({
      modal: (
        <MaintenancesHistoryModal
          equipment={equipment}
          maintenances={maintenances}
          admin={this.props.admin}
          establishmentSettings={establishmentSettings}
          establishment={this.props.establishment}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }
  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.materialsTypesFilter) ||
      !Util.emptyString(this.state.brandModelFilter) ||
      !Util.emptyString(this.state.floorRoomFilter) ||
      !Util.emptyString(this.state.affectationFilter) ||
      !Util.emptyString(this.state.warrantyFilter) ||
      !Util.emptyString(this.state.purchaseDateFilter) ||
      !Util.emptyString(this.state.maintenanceDateFilter) ||
      !Util.emptyString(this.state.stateFilter) ||
      !Util.emptyString(this.state.ownerFilter) ||
      !Util.emptyString(this.state.materiovigilanceFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      materialsTypesFilter: "",
      brandModelFilter: "",
      floorRoomFilter: "",
      affectationFilter: "",
      warrantyFilter: "",
      purchaseDateFilter: "",
      maintenanceDateFilter: "",
      stateFilter: "",
      ownerFilter: "",
      materiovigilanceFilter: "",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.equipments !== this.props.equipments) {
      this.setState({ orderedEquipments: this.props.equipments });
      // Reset paginator to page 1 if props.equipments did update
      this.paginator.paginationIndex = 1;
    }
  }

  render() {
    if (!this.props.equipments || this.props.equipments.length === 0)
      return null;

    var materialsTypesNode = this.props.materialsTypes.map((materialTypeId) => {
      return (
        <option key={materialTypeId._id} value={materialTypeId._id}>
          {materialTypeId.name}
        </option>
      );
    });

    var affectationsNode = Object.values(EqAffectation).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqAffectation." + option })}
        </option>
      );
    });

    var ownerNode = Object.values(EqOwner).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqOwner." + option })}
        </option>
      );
    });

    var stateNode = Object.values(EqState).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqState." + option })}
        </option>
      );
    });

    var materiovigilanceNode = Object.values(EqMateriovigilance).map(
      (option) => {
        return (
          <option key={option} value={option}>
            {this.props.intl.formatMessage({
              id: "EqMateriovigilance." + option,
            })}
          </option>
        );
      },
    );

    this.paginator.init(this.props.equipments.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let equipmentsNode = this.state.orderedEquipments.map((equipment) => {
      var maintenances = [];
      for (var maintenance of this.props.maintenances) {
        if (maintenance.equipment_id === equipment._id) {
          if (maintenance.establishment_id === equipment.establishment_id) {
            maintenances.push(maintenance);
          }
        }
      }

      // Get last maintenance date to allow filter on it
      let lastMaintenanceDate = null;
      if (maintenances.length > 0) {
        lastMaintenanceDate =
          maintenances[maintenances.length - 1].creation_date;
      }
      if (
        this.state.materialsTypesFilter &&
        this.state.materialsTypesFilter !== ""
      ) {
        if (equipment.material_type_id !== this.state.materialsTypesFilter)
          return null;
      }

      if (this.state.brandModelFilter && this.state.brandModelFilter !== "") {
        var brandName = this.getBrandName(equipment.brand_id);
        var modelName = this.getModelName(equipment.model_id);

        if (!equipment.serial_nbr) {
          equipment.serial_nbr = "";
        }

        if (
          !brandName ||
          !modelName ||
          !equipment.internal_ref ||
          (brandName.toLowerCase().indexOf(this.state.brandModelFilter) ===
            -1 &&
            modelName.toLowerCase().indexOf(this.state.brandModelFilter) ===
              -1 &&
            equipment.serial_nbr
              .toLowerCase()
              .indexOf(this.state.brandModelFilter) === -1 &&
            equipment.internal_ref
              .toLowerCase()
              .indexOf(this.state.brandModelFilter) === -1)
        ) {
          return null;
        }
      }

      if (this.state.floorRoomFilter && this.state.floorRoomFilter !== "") {
        if (
          ((typeof equipment.floor === "undefined" || equipment.floor === "") &&
            (typeof equipment.room === "undefined" || equipment.room === "")) ||
          (parseInt(equipment.floor) !== parseInt(this.state.floorRoomFilter) &&
            equipment.room &&
            equipment.room
              .toLowerCase()
              .indexOf(this.state.floorRoomFilter.toLowerCase()) === -1)
        )
          return null;
      }

      if (this.state.affectationFilter && this.state.affectationFilter !== "") {
        if (
          parseInt(equipment.affectation) !==
          parseInt(this.state.affectationFilter)
        )
          return null;
      }

      if (
        this.state.purchaseDateFilter &&
        this.state.purchaseDateFilter !== ""
      ) {
        if (
          !equipment.purchase_date ||
          new Date(equipment.purchase_date) <
            new Date(this.state.purchaseDateFilter)
        )
          return null;
      }

      if (
        this.state.maintenanceDateFilter &&
        this.state.maintenanceDateFilter !== ""
      ) {
        if (
          null === lastMaintenanceDate ||
          new Date(lastMaintenanceDate) >
            new Date(this.state.maintenanceDateFilter)
        )
          return null;
      }

      if (this.state.warrantyFilter && this.state.warrantyFilter !== "") {
        var equipmentWarranty =
          equipment.warranty !== undefined && equipment.warranty !== null
            ? equipment.warranty
            : false;
        if (equipmentWarranty !== JSON.parse(this.state.warrantyFilter))
          return null;
      }

      if (this.state.stateFilter && this.state.stateFilter !== "") {
        if (
          equipment.state === undefined ||
          parseInt(equipment.state) !== parseInt(this.state.stateFilter)
        )
          return null;
      }

      if (this.state.ownerFilter && this.state.ownerFilter !== "") {
        if (
          equipment.owner === undefined ||
          parseInt(equipment.owner) !== parseInt(this.state.ownerFilter)
        )
          return null;
      }
      var materiovigilance = undefined;

      for (var materiovigilances of this.props.materiovigilance) {
        if (materiovigilances.model_id === equipment.model_id) {
          materiovigilance = materiovigilances.materiovigilance;
        }
      }

      if (
        this.state.materiovigilanceFilter !== "" &&
        this.state.materiovigilanceFilter !== undefined
      ) {
        if (
          parseInt(this.state.materiovigilanceFilter) === 0 &&
          materiovigilance === undefined
        ) {
          // return materiovigilance;
          // console.log('Valide')
        } else if (
          parseInt(this.state.materiovigilanceFilter) !==
          parseInt(materiovigilance)
        ) {
          // if(this.state.materiovigilanceFilter=== '0' && materiovigilance.)
          return null;
        }
      }

      if (!this.paginator.keep()) return null;

      return (
        <EquipmentRow
          key={equipment._id}
          equipment={equipment}
          materiovigilance={materiovigilance}
          maintenances={maintenances}
          maintenanceToSign={this.props.maintenancesToSign}
          openInterventionModal={(equipment) =>
            this.openInterventionModal(
              equipment,
              this.props.establishmentSettings,
            )
          }
          editEquipment={(equipment) => this.editEquipment(equipment)}
          deleteEquipment={(equipment) => this.deleteEquipment(equipment)}
          openMaintenancesHistoryModal={(equipment) =>
            this.openMaintenancesHistoryModal(
              equipment,
              maintenances,
              this.props.establishmentSettings,
            )
          }
          requestModal={(equipment, RequestType) =>
            this.requestModal(equipment, RequestType)
          }
        />
      );
    });

    return (
      <React.Fragment>
        <table id="equipments" className="table tablee4coll">
          <thead>
            <tr className="d-flex align-items-center h-100">
              <th scope="col" className="col">
                <span
                  className="btn-link cursor-pointer"
                  onClick={() => this.orderEquipmentsBy("material_type")}
                >
                  <strong>
                    <FormattedMessage id="Type" />
                  </strong>
                </span>
              </th>
              <th scope="col" className="col" style={{ overflow: "visible" }}>
                <span
                  className="btn-link cursor-pointer"
                  onClick={() => this.orderEquipmentsBy("brand")}
                >
                  <strong>
                    <FormattedMessage id="Brand" />
                  </strong>
                </span>{" "}
                /{" "}
                <span
                  className="btn-link cursor-pointer"
                  onClick={() => this.orderEquipmentsBy("model_id")}
                >
                  <strong>
                    <FormattedMessage id="Model" />
                  </strong>
                </span>
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Location" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Affectation" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Warranty" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="Last.Maintenance.Date" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="State" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="Owner" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="Materiovigilance" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              id="equipmentsFilters"
              className="d-flex align-items-center h-100"
            >
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.materialsTypesFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ materialsTypesFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {materialsTypesNode}
                </select>
              </td>
              <td className="col">
                <input
                  className="form-control"
                  type="text"
                  value={this.state.brandModelFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({
                      brandModelFilter: e.target.value.toLowerCase(),
                    });
                  }}
                />
              </td>
              <td className="col">
                <input
                  className="form-control"
                  type="text"
                  value={this.state.floorRoomFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ floorRoomFilter: e.target.value });
                  }}
                />
              </td>
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.affectationFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ affectationFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {affectationsNode}
                </select>
              </td>
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.warrantyFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ warrantyFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  <option value="true">
                    {this.props.intl.formatMessage({ id: "Yes" })}
                  </option>
                  <option value="false">
                    {this.props.intl.formatMessage({ id: "No" })}
                  </option>
                </select>
              </td>
              <td className="col">
                <input
                  className="form-control"
                  type="date"
                  value={this.state.maintenanceDateFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ maintenanceDateFilter: e.target.value });
                  }}
                />
              </td>
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.stateFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ stateFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {stateNode}
                </select>
              </td>
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.ownerFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ ownerFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {ownerNode}
                </select>
              </td>
              <td className="col">
                <select
                  className="form-control"
                  value={this.state.materiovigilanceFilter}
                  disabled={disableFormInput}
                  onChange={(e) => {
                    this.setState({ materiovigilanceFilter: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {materiovigilanceNode}
                </select>
              </td>
              <td className="col text-center">
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "warning" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </td>
            </tr>
            {equipmentsNode}
          </tbody>
        </table>

        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    materialsTypes: state.materialsTypes,
    brands: state.brands,
    models: state.models,
    establishment: state.establishment,
    establishmentSettings: state.establishmentSettings,
    maintenances: state.maintenances,
    materiovigilance: state.materiovigilance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Equipments));
